import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class DepartmentService {
  private readonly apiPrefix = '/client/departments';

  constructor(
    private api: ApiService,
  ) {
  }

  getDepartments() {
    return this.api.get(`${this.apiPrefix}`);
  }
}
