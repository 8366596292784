import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class SchoolService {
  private apiPrefix = '/client/schools';

  constructor(
    private api: ApiService,
  ) {
  }

  getCitySchools(cityId: number) {
    return this.api.get(`${this.apiPrefix}/city/${cityId}?isAll=1`);
  }

  createSchool(data: object) {
    return this.api.post(`${this.apiPrefix}`, data);
  }
}
