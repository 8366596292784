import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { Observable } from "rxjs";

@Injectable()
export class UserProfileService {
  private readonly apiPrefix = '/client/profile';

  constructor(
    private apiService: ApiService
  ) {
  }

  updateProfile(data: any): Observable<any> {
    return this.apiService.patch(`${this.apiPrefix}`, data);
  }

  createProfilePhotoUpload(mimeType: string) {
    return this.apiService.post(`${this.apiPrefix}/photo/upload`, {
      mimeType,
    });
  }

  saveProfilePhoto(requestId: string) {
    return this.apiService.patch(`${this.apiPrefix}/photo/upload/${requestId}`, {});
  }

  removeProfilePhoto() {
    return this.apiService.delete(`${this.apiPrefix}/photo`);
  }

  changeEmailRequest(data: object) {
    return this.apiService.patch(`${this.apiPrefix}/email`, data);
  }

  validateChangeEmail(requestId: string, data: object) {
    return this.apiService.patch(`${this.apiPrefix}/email/${requestId}`, data);
  }

  deleteAccountRequest(data: object) {
    return this.apiService.post(`${this.apiPrefix}/delete-my-account`, data);
  }

  verifyDeleteAccount(requestId: string, data: object) {
    return this.apiService.patch(`${this.apiPrefix}/delete-my-account/${requestId}`, data);
  }

  setOnboardingCompleted() {
    return this.apiService.post(`${this.apiPrefix}/onboarding/completed`, {});
  }

  updateLevel(data: object) {
    return this.apiService.patch(`${this.apiPrefix}/level`, data);
  }

  resetUserPlanWithDate(data: object) {
    return this.apiService.patch(`${this.apiPrefix}/plan-reset`, data);
  }
}
