import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class GradeService {
  private apiPrefix = '/client/grades';

  constructor(
    private api: ApiService
  ) {
  }

  getGrades() {
    return this.api.get(`${this.apiPrefix}`);
  }
}
